@import "~bootstrap/scss/bootstrap";

.clickable{
    cursor: pointer;
}

.panel-heading span {
    margin-top: -20px;
    font-size: 15px;
}

.top-buffer {
    margin-top:20px;
}

.btn-primary {
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
}

.btn-primary:hover {
    color: #fff;
    background-color: #286090;
    border-color: #204d74;
}

.btn-success {
    color: #fff;
    background-color: #5cb85c;
    border-color: #4cae4c;
}

.btn-success:hover {
    color: #fff;
    background-color: #449d44;
    border-color: #398439;
}